/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Config
import { themeVars } from '../../../config/styles';
import { bp } from '../../../config/breakpoints';

const SectionTitle = (props) => {
  const { children, marginBottom, fontSize, centered } = props;

  const cssTitle = css`
    ${centered && 'text-align: center'};
    color: ${props.color};
    font-size: ${fontSize}rem;
    margin-bottom: ${marginBottom}rem;
    line-height: 1;

    font-family: aviano-sans, sans-serif;
    font-weight: 900;
    font-style: normal;

    @media (min-width: ${bp.mn_md} and max-width: ${bp.mx_xl}) {
      text-align: center;
    }
  `;

  return <h2 css={cssTitle}>{children}</h2>;
};

SectionTitle.defaultProps = {
  centered: false,
  color: themeVars.root.primary,
  fontSize: 3.5,
  marginBottom: 3,
};

SectionTitle.propTypes = {
  /* The title */
  children: PropTypes.node.isRequired,
  /* The margin to apply to the bottom of the components in rem */
  marginBottom: PropTypes.number,
  /** The color of the text */
  color: PropTypes.string,
  /** The font size in rem */
  fontSize: PropTypes.number,
  /** Centre align the component */
  centered: PropTypes.bool,
};

export default SectionTitle;
